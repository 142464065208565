import React from "react";
import { Dialog } from "@mui/material";
import { useTranslation } from "next-i18next";
import ModalHeader from "./components/modalHeader";
import LanguagesList from "./components/languagesList";

interface Props {
  open: boolean;
  onClose: () => void;
}

const LanguageChangeModal = ({ open, onClose }: Props) => {
  const { t } = useTranslation("common");
  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        classes={{
          root: "languageModal",
          paper: "languageModal",
        }}
      >
        <div className="flex w-[456px] max-w-[100%] flex-col items-center justify-center rounded-[16px] px-4 py-3 md:px-12 md:py-8">
          <ModalHeader title={t("selectLanguage")} onClose={onClose} />
          <LanguagesList />
        </div>
      </Dialog>
    </>
  );
};

export default LanguageChangeModal;
