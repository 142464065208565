import { authRequest } from "../../utils/request/authRequest";

export interface BonusResponse {
    walletConfirmationAmount: number;
    claimedBonusCDO: number;
    availableBonusCDO: number;
    error?: any;
}

export const checkBonusInfo = async (): Promise<BonusResponse> => {
  const response = await authRequest(
    `v1/users/bonus-info`,
    {
      method: "GET",
      headers: {
        "Content-Type": `application/json`,
      },
    },
  );
  return response.data;
};
