import { authRequest } from "../../utils/request/authRequest";

export interface RoundResponse {
    secondsLeft: number,
    cdoLeft: number,
    nextPrice: number,
    currentPrice: number,
    error?: any;
}

export const BonusesRoundInfo = async (): Promise<RoundResponse> => {
  const response = await authRequest(
    `v1/users/bonuses-round-info`,
    {
      method: "GET",
      headers: {
        "Content-Type": `application/json`,
      },
    },
  );
  return response.data;
};
