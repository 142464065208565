import React from "react";
import clsx from "clsx";
import Link from "next/link";
import IconClose from "../../../../assets/svgs/iconCloseBar.svg";
import { useTranslation } from "next-i18next";

interface NavbarInfoProps {
  icon?: () => JSX.Element;
  content: any;
  commingSoon?: boolean;
  button?: {
    label: string;
    url: string;
  };
}

export default function NavbarInfo({
  icon,
  content,
  commingSoon,
  button,
}: NavbarInfoProps) {
  const { t } = useTranslation("common");
  const [closed, setClosed] = React.useState<boolean>(false);

  const handleCloseBar = () => {
    setClosed(true);
  };

  if (closed) return <></>;

  return (
    <div className="bg-gradient-infinity-pass relative">
      <div className="container !py-[16px]">
        <div className="flex flex-col items-start justify-between md:flex-row md:items-center">
          <p className="flex items-center gap-[15px] text-heading text-white">
            {icon && (
              <span className="w-[30px] flex-none [&>svg]:mx-auto [&>svg]:max-w-full">
                {icon()}
              </span>
            )}
            {content}
            {commingSoon && (
              <span className="rounded-[24px] bg-white px-2 py-1 text-base text-pictionBlue">
                {t("comingSoonNavbarPillText")}
              </span>
            )}
          </p>
          <div className="ml-auto mt-4 flex-none text-right md:mt-0">
            {button && (
              <Link
                className={clsx(
                  "block rounded-[8px] bg-white px-4 pb-[8px] pt-[7px] text-base text-pictionBlue transition-all duration-300 hover:bg-black hover:text-white",
                  commingSoon &&
                    "pointer-events-none bg-black text-white opacity-10",
                )}
                href={button.url}
              >
                {button.label}
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
