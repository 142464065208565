import React from "react";
import clsx from "clsx";

interface Props {
  base?: boolean;
  gray1?: boolean;
  gray2?: boolean;
  gray3?: boolean;
  gray4?: boolean;
  gray5?: boolean;
  white?: boolean;
  success?: boolean;
  center?: boolean;
  blue?: boolean;
  displayS?: boolean;
  displayM?: boolean;
  firs?: boolean;
  subheading?: boolean;
  uppercase?: boolean;
  caption?: boolean;
  caption2?: boolean;
  heading?: boolean;
  semiBold?: boolean;
  normal?: boolean;
  inter?: boolean;
  light3?: boolean;
  children: React.ReactNode;
}

const Paragraph = ({
  children,
  base,
  gray1,
  gray2,
  gray3,
  gray4,
  gray5,
  blue,
  center,
  displayS,
  displayM,
  subheading,
  firs,
  uppercase,
  semiBold,
  caption,
  caption2,
  white,
  success,
  heading,
  normal,
  inter,
  light3,
}: Props) => {
  return (
    <p
      className={clsx({
        "text-base": base,
        "text-caption": caption,
        "text-caption2": caption2,
        "text-heading": heading,
        "text-gray1": gray1,
        "text-gray2": gray2,
        "text-gray3": gray3,
        "text-gray4": gray4,
        "text-gray5": gray5,
        "text-white": white,
        "text-success": success,
        "text-pictionBlue": blue,
        "text-center": center,
        "text-displayS": displayS,
        "text-displayM": displayM,
        "font-firs": firs,
        "text-subHeading": subheading,
        uppercase: uppercase,
        "font-semibold": semiBold,
        "font-inter": inter,
        "font-normal": normal,
        "text-light3": light3,
      })}
    >
      {children}
    </p>
  );
};

export default Paragraph;
