import { authRequest } from "../../utils/request/authRequest";
import { getAccount } from "@wagmi/core";

export interface CDOBalanceResponse {
  balance: string;
}

export const getUserCDOBalance = async (): Promise<CDOBalanceResponse> => {
    const account = getAccount();
    const response = await authRequest(
    `v1/contract/cdo/` + account.address + `/balance`, {
        method: "GET",
        headers: {
            "Content-Type": `application/json`,
        },
        },
    {
    },
  );
  return response.data;
};
