import { LngDto } from "../../../constants/languages";
import { useRouter } from "next/router";
import Paragraph from "../../typography/paragraph";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import { useTranslation } from "next-i18next";
import ReactCountryFlag from "react-country-flag";
import React from "react";
import Link from "next/link";

interface Props {
  lng: LngDto;
}

const LanguageLink = ({ lng }: Props) => {
  const router = useRouter();
  const { i18n } = useTranslation();
  const changeLanguage = () => {
    if (router.pathname?.includes("/media-mentions/")) {
      router
        .push(
          {
            pathname: "/media-mentions/[slug]",
            query: { slug: router.query.slug },
          },
          {},
          { locale: lng.locale },
        )
        .then(() => {
          lng.locale === "pk"
            ? document.body.classList.add("pk")
            : document.body.classList.remove("pk");
        });
    } else {
      router
        .push(
          {
            pathname: "/blog/[slug]",
            query: { slug: router.query.slug },
          },
          {},
          { locale: lng.locale },
        )
        .then(() => {
          lng.locale === "pk"
            ? document.body.classList.add("pk")
            : document.body.classList.remove("pk");
        });
    }
  };

  const renderBody = () => (
    <div className="border-b bg-white px-2 py-1.5 transition-all duration-300 hover:bg-gray-100">
      <div className="flex items-center">
        <ReactCountryFlag
          countryCode={lng.locale === "en" ? "us" : lng.locale}
          svg
          style={{
            width: "20px",
          }}
          title={lng.locale}
        />
        <div className="ml-4">
          <Paragraph gray1 base>
            {lng.localeName}
          </Paragraph>
        </div>
        <div className="ml-4">
          <Paragraph gray4 base>
            {lng.name}
          </Paragraph>
        </div>
        {i18n.language === lng.locale && (
          <div className="color-gray4 ml-auto text-base">
            <CheckRoundedIcon fontSize={"inherit"} color={"inherit"} />
          </div>
        )}
      </div>
    </div>
  );

  return (
    <>
      {router.pathname?.includes("/media-mentions/") ||
      router.pathname?.includes("/blog/") ? (
        <button className="block w-full bg-red-500" onClick={changeLanguage}>
          {renderBody()}
        </button>
      ) : (
        <Link
          href={router.pathname}
          locale={lng.locale}
          onClick={() => {
            i18n.changeLanguage(lng.locale).then(() => {
              lng.locale === "pk"
                ? document.body.classList.add("pk")
                : document.body.classList.remove("pk");
            });
          }}
        >
          {renderBody()}
        </Link>
      )}
    </>
  );
};

export default LanguageLink;
