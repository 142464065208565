import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { Avatar } from "@/components/atoms";
import useUserData from "@/hooks/useUserData";
import { MenuItem, MenuList, styled } from "@mui/material";
import { menuItems } from "../dashboard/UpperLeftNavigation"
import { ZIM, ZIMConversation } from "zego-zim-web";
import { useMutation } from "wagmi";
import { ErrorDto } from "@/types/error.dto";
import { getMyPeopleCount } from "@/queries/people/getFollowersAndFriendsCount.query";
import CdoBalance from "../landing/navbar/cdoBalance"; 
import { EarnCdoStep2 } from "@/components/sections/pages/socialGraph/EarnCdoStep2";
import { EarnCdoStep1 } from "../socialGraph/EarnCdoStep1";

interface MenuItemData {
  path: string;
  icon: JSX.Element;
  label: string;
}

interface Props {
  isVisible: boolean;
  close: any;
}

const MobileNavbar = ( { isVisible, close }: Props ) => {
  const router = useRouter();
  const getUser = useUserData();
  const [closeVisible, setCloseVisible] = useState<boolean>(true);
  const [unreadMessages, setUnreadMessages] = useState<number>(() => {
    const storedCount = localStorage.getItem("unreadMessages");
    return storedCount ? parseInt(storedCount, 10) : 0;
  });  const [zim, setZim] = useState<ZIM | null>(null);
  const [onChainFollowersCount, setOnChainFollowersCount] = useState<number | undefined>(undefined);
  const [followingCount, setFollowingCount] = useState<number | undefined>(undefined);
  
  const formatNumber = (num: number, precision = 2) => {
    const map = [
        { suffix: 'T', threshold: 1e12 },
        { suffix: 'B', threshold: 1e9 },
        { suffix: 'M', threshold: 1e6 },
        { suffix: 'K', threshold: 1e3 },
        { suffix: '', threshold: 1 },
    ];
    const found = map.find((x) => Math.abs(num) >= x.threshold);
    if (found) {
        let formatted = (num / found.threshold).toFixed(precision);
        if (formatted.indexOf('.') !== -1) {
            formatted = formatted.replace(/\.?0+$/, '');
        }
        return formatted + found.suffix;
    } else {
        return num.toString();
    }
  };

  useEffect(() => {
    (async () => {
      const { ZIM } = await import("zego-zim-web");
      var zim = ZIM.getInstance();
      zim.setLogConfig({logLevel: 'disable'})
      setZim(zim);
    })();
  }, []);

  useEffect(() => {
    isVisible && getPeopleCount.mutate();
  },[isVisible])

  const getPeopleCount = useMutation(getMyPeopleCount, {
    onSuccess: (data) => {
      // console.log(data)
      setOnChainFollowersCount(data.follow.followers);
      setFollowingCount(data.follow.following);
    },
    onError: (e: ErrorDto) => {
      console.error(e);
    },
  });

  zim && zim.on('conversationTotalUnreadMessageCountUpdated', function(zim, { totalUnreadMessageCount }){
    localStorage.setItem("unreadMessages", totalUnreadMessageCount.toString());
    setUnreadMessages(totalUnreadMessageCount);
  })
  
  const handleMenuItemClick = (path: string) => {
    close();
    setTimeout(() => {
      setCloseVisible(false);
      router.push(path).then(() => { });
    }, 300);
  };

  return (
    <>
      <div className={`mobileNavbarBackground ${isVisible && 'visible' }`}>
        <div className="flex column">
          <div className={`mobileNavbar ${isVisible && "visible" } pb-16`}>
            <div className={`row mb-2 flex justify-between overflow-hidden rounded-2xl ml-0 pb-[16px] pt-[16px]`}>
              <div className="flex row mb-2 m-0">
                <a href={`/@${getUser?.username}`}>
                  <Avatar
                    size="medium" color="indigo" rounded className="shrink-0 mb-1 mr-1 p-0"
                    avatarUrl={getUser?.profile.avatarUrl}
                    username={getUser?.username}
                  />
                </a>
                <div className="flex flex-row gap-1 mt-1">
                  <a href={`/@${getUser?.username}`}>
                    <span className="break-words text-[14px] font-medium text-gray-900">
                      {getUser?.profile.firstName} {getUser?.profile.lastName}
                    </span>
                  </a>
                    {
                      getUser?.profile.isSocialAccountsVerified &&
                      <div className="flex items-end mb-[2px]">
                        <svg  xmlns="http://www.w3.org/2000/svg"  width="20"  height="20"  viewBox="0 0 24 24"  fill="#5850EC"  className="icon icon-tabler icons-tabler-filled icon-tabler-rosette-discount-check"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12.01 2.011a3.2 3.2 0 0 1 2.113 .797l.154 .145l.698 .698a1.2 1.2 0 0 0 .71 .341l.135 .008h1a3.2 3.2 0 0 1 3.195 3.018l.005 .182v1c0 .27 .092 .533 .258 .743l.09 .1l.697 .698a3.2 3.2 0 0 1 .147 4.382l-.145 .154l-.698 .698a1.2 1.2 0 0 0 -.341 .71l-.008 .135v1a3.2 3.2 0 0 1 -3.018 3.195l-.182 .005h-1a1.2 1.2 0 0 0 -.743 .258l-.1 .09l-.698 .697a3.2 3.2 0 0 1 -4.382 .147l-.154 -.145l-.698 -.698a1.2 1.2 0 0 0 -.71 -.341l-.135 -.008h-1a3.2 3.2 0 0 1 -3.195 -3.018l-.005 -.182v-1a1.2 1.2 0 0 0 -.258 -.743l-.09 -.1l-.697 -.698a3.2 3.2 0 0 1 -.147 -4.382l.145 -.154l.698 -.698a1.2 1.2 0 0 0 .341 -.71l.008 -.135v-1l.005 -.182a3.2 3.2 0 0 1 3.013 -3.013l.182 -.005h1a1.2 1.2 0 0 0 .743 -.258l.1 -.09l.698 -.697a3.2 3.2 0 0 1 2.269 -.944zm3.697 7.282a1 1 0 0 0 -1.414 0l-3.293 3.292l-1.293 -1.292l-.094 -.083a1 1 0 0 0 -1.32 1.497l2 2l.094 .083a1 1 0 0 0 1.32 -.083l4 -4l.083 -.094a1 1 0 0 0 -.083 -1.32z" /></svg>
                      </div>
                    }
                </div>
                <span onClick={() => {router.push(`/@${getUser?.username}`)}} className="cursor-pointer text-gray-600 text-[12px] pt-0 mt-0 break-words">@{getUser?.username}</span>
                { onChainFollowersCount !== undefined && followingCount !== undefined && 
                  <div className="flex flex-row pt-3 gap-1">
                    <span className="text-gray-600 text-[12px] break-words">
                      <span className="text-gray-900 font-medium">{formatNumber(followingCount)} </span>
                      Following · 
                    </span>
                    <span className="text-gray-600 text-[12px] break-words">
                      <span className="text-gray-900 font-medium">{formatNumber(onChainFollowersCount)} </span>
                      Followers
                    </span>
                  </div>
                }
              </div>
              <div className="pl-4 pr-4"><div className="border-b mt-2 mb-2"/></div>
              <MenuList style={{ padding: 0 }}>
                {menuItems.map((item) => (
                  <StyledMenuItem
                    key={item.path}
                    isSelected={router.pathname === item.path}
                    onClick={() => handleMenuItemClick(item.path)}
                  >
                    <div className={`${router.pathname === item.path ? "h-[20px] w-[4px] bg-indigo-600 mr-[12px] rounded-tr-lg rounded-br-lg" : "mr-[15px]"}`} />
                    <div className={`flex column h-full w-full p-3 rounded-xl mr-4 ${router.pathname === item.path && "bg-gray-50"}`}>
                      <div className="mt-[2px]">
                        {React.cloneElement(item.icon, {
                          stroke: router.pathname === item.path ? "#5850EC" : "#6B7280",
                        })}
                      </div>
                      <span className={`text-[14px] ml-2 ${router.pathname === item.path ? "text-gray-900 font-medium" : "text-gray-700"}`}>
                        {
                          item.label === "Messages" && unreadMessages > 0 ?
                          <div className="flex flex-row items-center gap-2 mt-[-2px]">
                            {item.label}
                            <div className="flex flex-row rounded-full min-w-[16px] h-[16px] justify-center bg-indigo-100 text-[11px] text-indigo-600">
                              {unreadMessages}
                            </div>
                          </div>
                          : <>{item.label}</>
                        }
                      </span>
                    </div>
                  </StyledMenuItem>
                ))}
              </MenuList>
              {/* <div className="pl-4 pr-4"><div className="border-b mt-2 mb-2"/></div> */}
            </div>
            <CdoBalance isMobile={true} />
            <div className="pl-4 pr-2">
              <EarnCdoStep1 />
              <EarnCdoStep2 />
            </div>
          </div>
          <div className={`cursor-pointer ${closeVisible && 'w-[30%] sm:w-[40%]'}`} onClick={close}></div>
        </div>
      </div>    
    </>
  );
};

const StyledMenuItem = styled(MenuItem)<{isSelected: any;}>(
  ({ isSelected }: { isSelected: boolean }) => ({
    padding: "0px",
    margin: "0px",
    "&:hover": { backgroundColor: "transparent" },
  }),
);

export default MobileNavbar;
