//* Should be in environment variables
const isProd = process.env.NEXT_PUBLIC_APP_CONFIG === "prod";
const isStage = process.env.NEXT_PUBLIC_APP_CONFIG === "staging";

const CC_ADDRESS_MAINNET = {
  BUSD: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
  USDT: "0x55d398326f99059fF775485246999027B3197955",
  USDC: "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
  CDO: "0x0e441841C9F1F665bf3D86c278ccf09c969406eD"
}
const CC_ADDRESS_TESTNET = {
  BUSD: "0xeD24FC36d5Ee211Ea25A80239Fb8C4Cfd80f12Ee",
  USDT: "0x337610d27c682E347C9cD60BD4b3b107C9d34dDd",
  USDC: "0x053Fc65249dF91a02Ddb294A081f774615aB45F4",
  CDO: "0xd682d53Fecd3645d6Ad468D9BFe732754AE66b69"
}

export const BUSD_ADDRESS = (isProd || isStage
  ? CC_ADDRESS_MAINNET.BUSD
  : CC_ADDRESS_TESTNET.BUSD) as `0x${string}`;


export const USDT_ADDRESS = (isProd || isStage
  ? CC_ADDRESS_MAINNET.USDT
  : CC_ADDRESS_TESTNET.USDT) as `0x${string}`;

export const USDC_ADDRESS = (isProd || isStage
  ? CC_ADDRESS_MAINNET.USDC
  : CC_ADDRESS_TESTNET.USDC) as `0x${string}`;

  export const CDO_ADDRESS = (isProd || isStage
  ? CC_ADDRESS_MAINNET.CDO
  : CC_ADDRESS_TESTNET.CDO) as `0x${string}`;