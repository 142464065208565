export const cdoContractAddress =
  process.env.NEXT_PUBLIC_APP_CONFIG === "prod" ||
  process.env.NEXT_PUBLIC_APP_CONFIG === "staging"
    ? "0x0e441841C9F1F665bf3D86c278ccf09c969406eD"
    : "0xd682d53Fecd3645d6Ad468D9BFe732754AE66b69";
export const CDO_DECIMALS = 18;
export const cdoStaticAbiObject = ([
               {
                  "inputs":[
                     
                  ],
                  "stateMutability":"nonpayable",
                  "type":"constructor"
               },
               {
                  "inputs":[
                     {
                        "internalType":"address",
                        "name":"spender",
                        "type":"address"
                     },
                     {
                        "internalType":"uint256",
                        "name":"allowance",
                        "type":"uint256"
                     },
                     {
                        "internalType":"uint256",
                        "name":"needed",
                        "type":"uint256"
                     }
                  ],
                  "name":"ERC20InsufficientAllowance",
                  "type":"error"
               },
               {
                  "inputs":[
                     {
                        "internalType":"address",
                        "name":"sender",
                        "type":"address"
                     },
                     {
                        "internalType":"uint256",
                        "name":"balance",
                        "type":"uint256"
                     },
                     {
                        "internalType":"uint256",
                        "name":"needed",
                        "type":"uint256"
                     }
                  ],
                  "name":"ERC20InsufficientBalance",
                  "type":"error"
               },
               {
                  "inputs":[
                     {
                        "internalType":"address",
                        "name":"approver",
                        "type":"address"
                     }
                  ],
                  "name":"ERC20InvalidApprover",
                  "type":"error"
               },
               {
                  "inputs":[
                     {
                        "internalType":"address",
                        "name":"receiver",
                        "type":"address"
                     }
                  ],
                  "name":"ERC20InvalidReceiver",
                  "type":"error"
               },
               {
                  "inputs":[
                     {
                        "internalType":"address",
                        "name":"sender",
                        "type":"address"
                     }
                  ],
                  "name":"ERC20InvalidSender",
                  "type":"error"
               },
               {
                  "inputs":[
                     {
                        "internalType":"address",
                        "name":"spender",
                        "type":"address"
                     }
                  ],
                  "name":"ERC20InvalidSpender",
                  "type":"error"
               },
               {
                  "inputs":[
                     {
                        "internalType":"address",
                        "name":"owner",
                        "type":"address"
                     }
                  ],
                  "name":"OwnableInvalidOwner",
                  "type":"error"
               },
               {
                  "inputs":[
                     {
                        "internalType":"address",
                        "name":"account",
                        "type":"address"
                     }
                  ],
                  "name":"OwnableUnauthorizedAccount",
                  "type":"error"
               },
               {
                  "anonymous":false,
                  "inputs":[
                     {
                        "indexed":true,
                        "internalType":"address",
                        "name":"owner",
                        "type":"address"
                     },
                     {
                        "indexed":true,
                        "internalType":"address",
                        "name":"spender",
                        "type":"address"
                     },
                     {
                        "indexed":false,
                        "internalType":"uint256",
                        "name":"value",
                        "type":"uint256"
                     }
                  ],
                  "name":"Approval",
                  "type":"event"
               },
               {
                  "anonymous":false,
                  "inputs":[
                     {
                        "indexed":true,
                        "internalType":"address",
                        "name":"previousOwner",
                        "type":"address"
                     },
                     {
                        "indexed":true,
                        "internalType":"address",
                        "name":"newOwner",
                        "type":"address"
                     }
                  ],
                  "name":"OwnershipTransferred",
                  "type":"event"
               },
               {
                  "anonymous":false,
                  "inputs":[
                     {
                        "indexed":true,
                        "internalType":"address",
                        "name":"from",
                        "type":"address"
                     },
                     {
                        "indexed":true,
                        "internalType":"address",
                        "name":"to",
                        "type":"address"
                     },
                     {
                        "indexed":false,
                        "internalType":"uint256",
                        "name":"value",
                        "type":"uint256"
                     }
                  ],
                  "name":"Transfer",
                  "type":"event"
               },
               {
                  "inputs":[
                     {
                        "internalType":"address",
                        "name":"token",
                        "type":"address"
                     }
                  ],
                  "name":"addAllowedDepositToken",
                  "outputs":[
                     
                  ],
                  "stateMutability":"nonpayable",
                  "type":"function"
               },
               {
                  "inputs":[
                     {
                        "internalType":"address",
                        "name":"owner",
                        "type":"address"
                     },
                     {
                        "internalType":"address",
                        "name":"spender",
                        "type":"address"
                     }
                  ],
                  "name":"allowance",
                  "outputs":[
                     {
                        "internalType":"uint256",
                        "name":"",
                        "type":"uint256"
                     }
                  ],
                  "stateMutability":"view",
                  "type":"function"
               },
               {
                  "inputs":[
                     {
                        "internalType":"address",
                        "name":"spender",
                        "type":"address"
                     },
                     {
                        "internalType":"uint256",
                        "name":"value",
                        "type":"uint256"
                     }
                  ],
                  "name":"approve",
                  "outputs":[
                     {
                        "internalType":"bool",
                        "name":"",
                        "type":"bool"
                     }
                  ],
                  "stateMutability":"nonpayable",
                  "type":"function"
               },
               {
                  "inputs":[
                     {
                        "internalType":"address",
                        "name":"account",
                        "type":"address"
                     }
                  ],
                  "name":"balanceOf",
                  "outputs":[
                     {
                        "internalType":"uint256",
                        "name":"",
                        "type":"uint256"
                     }
                  ],
                  "stateMutability":"view",
                  "type":"function"
               },
               {
                  "inputs":[
                     {
                        "internalType":"uint256",
                        "name":"tokenIndex",
                        "type":"uint256"
                     },
                     {
                        "internalType":"uint256",
                        "name":"amount",
                        "type":"uint256"
                     }
                  ],
                  "name":"buy",
                  "outputs":[
                     
                  ],
                  "stateMutability":"nonpayable",
                  "type":"function"
               },
               {
                  "inputs":[
                     {
                        "internalType":"uint256",
                        "name":"",
                        "type":"uint256"
                     }
                  ],
                  "name":"currencies",
                  "outputs":[
                     {
                        "internalType":"address",
                        "name":"",
                        "type":"address"
                     }
                  ],
                  "stateMutability":"view",
                  "type":"function"
               },
               {
                  "inputs":[
                     
                  ],
                  "name":"currentPrice",
                  "outputs":[
                     {
                        "internalType":"uint256",
                        "name":"",
                        "type":"uint256"
                     }
                  ],
                  "stateMutability":"view",
                  "type":"function"
               },
               {
                  "inputs":[
                     
                  ],
                  "name":"decimals",
                  "outputs":[
                     {
                        "internalType":"uint8",
                        "name":"",
                        "type":"uint8"
                     }
                  ],
                  "stateMutability":"view",
                  "type":"function"
               },
               {
                  "inputs":[
                     
                  ],
                  "name":"feeWallet",
                  "outputs":[
                     {
                        "internalType":"address",
                        "name":"",
                        "type":"address"
                     }
                  ],
                  "stateMutability":"view",
                  "type":"function"
               },
               {
                  "inputs":[
                     
                  ],
                  "name":"getTokens",
                  "outputs":[
                     {
                        "internalType":"address[]",
                        "name":"",
                        "type":"address[]"
                     }
                  ],
                  "stateMutability":"view",
                  "type":"function"
               },
               {
                  "inputs":[
                     
                  ],
                  "name":"name",
                  "outputs":[
                     {
                        "internalType":"string",
                        "name":"",
                        "type":"string"
                     }
                  ],
                  "stateMutability":"view",
                  "type":"function"
               },
               {
                  "inputs":[
                     
                  ],
                  "name":"owner",
                  "outputs":[
                     {
                        "internalType":"address",
                        "name":"",
                        "type":"address"
                     }
                  ],
                  "stateMutability":"view",
                  "type":"function"
               },
               {
                  "inputs":[
                     
                  ],
                  "name":"renounceOwnership",
                  "outputs":[
                     
                  ],
                  "stateMutability":"nonpayable",
                  "type":"function"
               },
               {
                  "inputs":[
                     {
                        "internalType":"address",
                        "name":"wallet",
                        "type":"address"
                     },
                     {
                        "internalType":"uint256",
                        "name":"amount",
                        "type":"uint256"
                     }
                  ],
                  "name":"send",
                  "outputs":[
                     
                  ],
                  "stateMutability":"nonpayable",
                  "type":"function"
               },
               {
                  "inputs":[
                     {
                        "internalType":"address",
                        "name":"wallet",
                        "type":"address"
                     }
                  ],
                  "name":"setFeeWallet",
                  "outputs":[
                     
                  ],
                  "stateMutability":"nonpayable",
                  "type":"function"
               },
               {
                  "inputs":[
                     
                  ],
                  "name":"symbol",
                  "outputs":[
                     {
                        "internalType":"string",
                        "name":"",
                        "type":"string"
                     }
                  ],
                  "stateMutability":"view",
                  "type":"function"
               },
               {
                  "inputs":[
                     
                  ],
                  "name":"totalSupply",
                  "outputs":[
                     {
                        "internalType":"uint256",
                        "name":"",
                        "type":"uint256"
                     }
                  ],
                  "stateMutability":"view",
                  "type":"function"
               },
               {
                  "inputs":[
                     {
                        "internalType":"address",
                        "name":"to",
                        "type":"address"
                     },
                     {
                        "internalType":"uint256",
                        "name":"value",
                        "type":"uint256"
                     }
                  ],
                  "name":"transfer",
                  "outputs":[
                     {
                        "internalType":"bool",
                        "name":"",
                        "type":"bool"
                     }
                  ],
                  "stateMutability":"nonpayable",
                  "type":"function"
               },
               {
                  "inputs":[
                     {
                        "internalType":"address",
                        "name":"from",
                        "type":"address"
                     },
                     {
                        "internalType":"address",
                        "name":"to",
                        "type":"address"
                     },
                     {
                        "internalType":"uint256",
                        "name":"value",
                        "type":"uint256"
                     }
                  ],
                  "name":"transferFrom",
                  "outputs":[
                     {
                        "internalType":"bool",
                        "name":"",
                        "type":"bool"
                     }
                  ],
                  "stateMutability":"nonpayable",
                  "type":"function"
               },
               {
                  "inputs":[
                     {
                        "internalType":"address",
                        "name":"newOwner",
                        "type":"address"
                     }
                  ],
                  "name":"transferOwnership",
                  "outputs":[
                     
                  ],
                  "stateMutability":"nonpayable",
                  "type":"function"
               },
               {
                  "inputs":[
                     {
                        "internalType":"uint256",
                        "name":"newPrice",
                        "type":"uint256"
                     }
                  ],
                  "name":"updatePrice",
                  "outputs":[
                     
                  ],
                  "stateMutability":"nonpayable",
                  "type":"function"
               }
            ] as const);
