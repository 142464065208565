export const LANGUAGES_ARRAY: LngDto[] = [
  {
    locale: "en",
    name: "English",
    localeName: "English",
  },
  {
    locale: "pl",
    name: "Polish",
    localeName: "Polski",
  },
  {
    locale: "de",
    name: "german",
    localeName: "Deutsch",
  },
  {
    locale: "se",
    name: "Swedish",
    localeName: "svenska",
  },
  {
    locale: "nl",
    name: "Dutch",
    localeName: "Nederlands",
  },
  {
    locale: "pt",
    name: "Portuguese",
    localeName: "Português",
  },
  {
    locale: "fr",
    name: "French",
    localeName: "Français",
  },
  {
    locale: "it",
    name: "Italian",
    localeName: "Italiano",
  },
  {
    locale: "cn",
    name: "Chinese",
    localeName: "中國人",
  },
  {
    locale: "es",
    name: "Spanish",
    localeName: "Español",
  },
  {
    locale: "tr",
    name: "Turkish",
    localeName: "Türkçe",
  },
  {
    locale: "ru",
    name: "Russian",
    localeName: "Русский",
  },
  {
    locale: "bd",
    name: "bdali",
    localeName: "বেঙ্গলস্কি",
  },
  {
    locale: "ph",
    name: "Filipino",
    localeName: "Filipino",
  },

  {
    locale: "in",
    name: "Hindi",
    localeName: "हिंदी",
  },
  {
    locale: "id",
    name: "Indonesian",
    localeName: "bahasa Indonesia",
  },
  {
    locale: "jp",
    name: "Japanese",
    localeName: "日本",
  },
  {
    locale: "kr",
    name: "Korean",
    localeName: "한국인",
  },
  {
    locale: "th",
    name: "Thai",
    localeName: "แบบไทย",
  },
  {
    locale: "vi",
    name: "Vietnamese",
    localeName: "Tiếng Việt",
  },
  {
    locale: "pk",
    name: "Urdu",
    localeName: "اردو",
  },
];

export interface LngDto {
  locale: string;
  name: string;
  localeName: string;
}
