import React from "react";
import Paragraph from "../../typography/paragraph";
import { IconButton } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";

interface Props {
  title: string;
  onClose: () => void;
}

const ModalHeader = ({ title, onClose }: Props) => {
  return (
    <div className="header flex w-full items-center justify-between">
      <Paragraph semiBold gray1 heading>
        {title}
      </Paragraph>
      <div className="text-gray3 hover:text-red-400">
        <IconButton
          size={"small"}
          color={"inherit"}
          type={"button"}
          onClick={onClose}
        >
          <CancelIcon
            fontSize={"small"}
            color={"inherit"}
            sx={{ transition: "all linear 0.3s" }}
          />
        </IconButton>
      </div>
    </div>
  );
};

export default ModalHeader;
