import React from "react";
import { LANGUAGES_ARRAY, LngDto } from "../../../constants/languages";
import Paragraph from "../../typography/paragraph";
import LanguageLink from "./languageLink";
import { useTranslation } from "next-i18next";

const LanguagesList = () => {
  const [showOtherLanguages, setShowOtherLanguages] =
    React.useState<boolean>(false);
  const { t } = useTranslation("common");

  return (
    <div className="mt-6 w-full overflow-hidden rounded-[16px] border pt-1">
      <div className="styledBar max-h-[70vh] overflow-y-auto">
        {LANGUAGES_ARRAY.map((lng: LngDto, index: number) => {
          if (!showOtherLanguages && index > 6) return <div key={index}></div>;
          return <LanguageLink lng={lng} key={index} />;
        })}
      </div>
      {LANGUAGES_ARRAY.length > 7 && (
        <button
          onClick={() => setShowOtherLanguages(!showOtherLanguages)}
          className="w-full bg-white px-2 py-1.5 transition-all duration-300 hover:bg-gray-100"
        >
          <Paragraph blue base>
            {showOtherLanguages ? t("lessLanguages") : t("otherLanguages")} »
          </Paragraph>
        </button>
      )}
    </div>
  );
};

export default LanguagesList;
