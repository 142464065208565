import { useWalletClient } from "wagmi";
import { getContract } from "wagmi/actions";
import { useSelector } from "react-redux";
import {
  selectCandaoBadgesContractAddress,
  selectCandaoCoordinatorContractAddress,
  selectCandaoReferralContractAddress,
  selectInfinityPassContractAddress,
  selectOnchainRefV2ContractAddress,
  selectSelectedChain,
} from "../../Redux/slices/config.slice";
import useAbi from "../useAbi";
import {
  xcdoContractAddress,
  fpcdoContractAddress,
  fpcdoStaticAbiObject,
  xcdoStaticAbiObject,
} from "../../components/_v2/tokenSwapBox/_data";

import { cdoContractAddress } from "@/v2/components/organisms/swapbox-erc20/data/constans.CDO"
import { cdoStaticAbiObject } from "@/v2/components/organisms/swapbox-erc20/data/constans.CDO"

const useContracts = () => {
  const { data: walletClient } = useWalletClient();

  const selectedChain = useSelector(selectSelectedChain);
  const candaoCoordinatorAddress = useSelector(
    selectCandaoCoordinatorContractAddress,
  );
  const candaoReferralAddress = useSelector(
    selectCandaoReferralContractAddress,
  );
  const candaoBadgesAddress = useSelector(selectCandaoBadgesContractAddress);
  const candaoRefV2Address = useSelector(selectOnchainRefV2ContractAddress);
  const candaoInfinityPassAddress = useSelector(
    selectInfinityPassContractAddress,
  );

  const {
    abiJSON,
    abiCurrency,
    abiCandaoBadge,
    abiInfinityPassJSON,
    onChainRefv2ABI,
  } = useAbi();

  // ! contracts
  const candaoContract = getContract({
    address: selectedChain?.chain.contracts
      .candao_coordinator_address as `0x${string}`,
    abi: abiJSON,
    walletClient: walletClient ?? undefined,
  });
  const cdoContract = getContract({
    address: cdoContractAddress,
    abi: cdoStaticAbiObject,
    walletClient: walletClient ?? undefined,
  });
  const cdoContractOld = getContract({
    address: cdoContractAddress,
    abi: cdoStaticAbiObject,
    walletClient: walletClient ?? undefined,
  });
  const xcdoContract = getContract({
    address: xcdoContractAddress,
    abi: xcdoStaticAbiObject,
    walletClient: walletClient ?? undefined,
  });
  const fpcdoContract = getContract({
    address: fpcdoContractAddress,
    abi: fpcdoStaticAbiObject,
    walletClient: walletClient ?? undefined,
  });
  const currencyContract = getContract({
    address: selectedChain?.token as `0x${string}`,
    abi: abiCurrency,
    walletClient: walletClient ?? undefined,
  });
  const candaoBadgeContract = getContract({
    address: selectedChain?.chain.contracts
      .candao_badges_address as `0x${string}`,
    abi: abiCandaoBadge,
    walletClient: walletClient ?? undefined,
  });
  const infinityPassContract = getContract({
    address: selectedChain?.chain.contracts
      .candao_infinity_pass_address as `0x${string}`,
    abi: abiInfinityPassJSON,
    walletClient: walletClient ?? undefined,
  });
  const currencyInfinityContract = getContract({
    address: selectedChain?.infinityToken as `0x${string}`,
    abi: abiCurrency,
    walletClient: walletClient ?? undefined,
  });

  const onChainV2Contract = getContract({
    address: selectedChain?.chain.contracts
      .candao_referral_v2_address as `0x${string}`,
    abi: onChainRefv2ABI,
    walletClient: walletClient ?? undefined,
  });

  return {
    abiJSON,
    abiCurrency,
    abiCandaoBadge,
    abiInfinityPassJSON,
    candaoContract,
    currencyContract,
    cdoContract,
    xcdoContract,
    fpcdoContract,
    candaoBadgeContract,
    infinityPassContract,
    selectedChain,
    currencyInfinityContract,
    onChainRefv2ABI,
    onChainV2Contract,
    candao_coordinator_address: candaoCoordinatorAddress,
    candao_badges_address: candaoBadgesAddress,
    candao_referral_address: candaoReferralAddress,
    candao_infinitypass_address: candaoInfinityPassAddress,
    candao_referral_v2_address: candaoRefV2Address,
  };
};

export default useContracts;
