import { authRequest } from "../../utils/request/authRequest";

export interface FollowerCountResponse {
    follow: {
        followers: number;
        following: number;
    },
    friends: number;
}

export const getMyPeopleCount = async (): Promise<FollowerCountResponse> => {
    const url = `v1/people/count`;

    const response = await authRequest(url, {
    method: "GET",
    headers: {
      "Content-Type": `application/json`,
    },
  });
  return response.data;
};
